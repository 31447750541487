import { useState } from 'react';

// const useCatchAsyncError = () => {
//     const [error, setError] = useState(null);

//     const catchError = useCallback((asyncFunc) => {
//         return async (...args) => {
//             try {
//                 await asyncFunc(...args);
//             } catch (err) {
//                 setError(err);
//             }
//         };
//     }, []);

//     return { catchError, error };
// };
const useCatchAsyncError = () => {
    const [, setError] = useState()
  
    return error => {
        setError(() => { throw error })
    }
}

export default useCatchAsyncError;

//使用
// import React, { useEffect } from 'react';
// import useCatchAsyncError from '../hooks/useCatchAsyncError';

// const DataFetcher = () => {
//     const { catchError, error } = useCatchAsyncError();

//     const fetchData = catchError(async () => {
//         const response = await fetch('https://api.example.com/data');
//         if (!response.ok) {
//             throw new Error('网络响应不是 OK');
//         }
//         const data = await response.json();
//         // 处理数据...
//     });

//     useEffect(() => {
//         fetchData();
//     }, [fetchData]);

//     if (error) {
//         return <div>发生错误：{error.message}</div>;
//     }

//     return <div>加载数据中...</div>;
// };

// export default DataFetcher;


// const useCatchAsyncError = () => {
//     const [error, setError] = useState()
  
//     return error => {
//         setError(() => { throw error })
//     }
//   }
  
  
// // 使用
// const Component = () => {
//     const setError = useCatchAsyncError();
  
//     useEffect(() => {
//         fetch('/abcd').then().catch((e) => {
//             setError(e)
//         })
//     })
// }