import React, { useState, useEffect } from "react";
import './index.css';
import {
    CloseOutlined,
    MenuOutlined
} from '@ant-design/icons';
import {
    Avatar,
    // Radio,
    // Space,
    Menu,
    Button,
    Dropdown,
    // Modal,
    Divider,
    Drawer,
    Badge,
    Popover
} from 'antd';
import { useLocation, useNavigate  } from "react-router-dom";
// import topRightLogo from '../../images/brand@2x.png';
import logo from '../../images/logo.png';
// import userLogo from '../../images/userLogo.png';
// import move_menu from '../../images/move_menu.png';
import { useTranslation } from 'react-i18next';
import { useTasks, useTasksDispatch } from '../../TasksContext';
import { useAuth, hasAuthParams } from 'react-oidc-context';
import integral_icon from '../../images/integral_icon.png';
import menu_styleid_icon from '../../images/menu_styleid_icon.png';
import menu_faceid_icon from '../../images/menu_faceid_icon.png';
import menu_two_faceid_icon from '../../images/menu_two_faceid_icon.png';
import { GetPoints } from '../../api/request.js';
import LoginInterceptModal from '../loginInterceptModal';
import { LOGOTEXT, LOGOBETA } from '../../constants';

// const userLanguage = navigator.language;

const MyHeader = () => {
    const auth = useAuth();
    const tasks = useTasks();
    const dispatch = useTasksDispatch();
    const [current, setCurrent] = useState('');
    const [displayName, setDisplayName] = useState('GoodApp');
    const [hasTriedSignin, setHasTriedSignin] = useState(false);
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [points, setPoints] = useState(0);
    const [openPopover, setOpenPopover] = useState(false);

    useEffect(() => {
        async function fetchData() {
            if (auth && auth?.isAuthenticated) {
                const jwtPayload = auth?.user.access_token.split('.')[1]
                const pointsData = await GetPoints({ token: auth?.user?.access_token, jwtpayload: jwtPayload });
                if ( pointsData.data?.code === 1000 ) {
                    setPoints(pointsData.data?.data.points)
                }
            }
        }
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth, tasks[3].userLoadingNum, tasks[5].sceneGenStatus]);

    useEffect(() => {
        const updateDisplayName = () => {
            if (auth.user) {
                setDisplayName(
                    auth?.user?.profile?.name ||
                    (auth?.user?.profile?.preferred_username.length >= 20 ? auth?.user?.profile?.preferred_username?.split(' ')[0].slice(0, 20) : auth?.user?.profile?.preferred_username) ||
                    'GoodApp'
                );
            } else {
                setDisplayName('GoodApp');
            }
        };
        updateDisplayName();
    }, [auth.user]);

    useEffect(() => {
        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
            !hasTriedSignin
        ) {
            auth.signinSilent().catch(() => {
                // console.log("Silent sign-in failed, user may need to login manually");
            });
            setHasTriedSignin(true);
        }
    }, [auth, hasTriedSignin]);

    useEffect(() => {
        try {
            if (location.pathname) {
                const parts = location.pathname.split("/");
                if (parts[1]) {
                    setCurrent(parts[1])
                } else {
                    setCurrent('')
                }
            }
        } catch (error) { /* empty */ }
    }, [location]);

    useEffect(() => {
        try {
            dispatch({
                type: 'set_login_intercept_modal',
                data: {
                    isModal: false,
                    isEver: tasks[6].isEver,
                    currentUrl: tasks[6].currentUrl
                }
            });
        } catch (error) { /* empty */ }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (open) {
            document.addEventListener('touchmove', preventDefault, { passive: false });
        } else {
            document.removeEventListener('touchmove', preventDefault);
        }

        return () => {
            document.removeEventListener('touchmove', preventDefault);
            document.body.style.overflow = '';
        };
    }, [open]);

    // const onChange = (e) => {
    //     try {
    //         i18n.changeLanguage(e.target.value);
    //     } catch (error) {}
    // };
    const onClick = (e) => {
        setCurrent(e.key);
        if (e.key === 'app') {
            return;
        }
        if (tasks[2].windowWidth <= 768) {
            onClose();
        }
        navigate('/' + e.key)
    };

    const AppClick = (key) => {
        hide();
        if (tasks[2].windowWidth <= 768) {
            onClose();
        }
        navigate(`/app/${key}`);
    }

    const hide = () => {
        setOpenPopover(false);
    };
    const handleOpenChange = (newOpen) => {
        setOpenPopover(newOpen);
    };

    const content = (
        <div className="popover-card">
            <div>
                <div className="lyo" onClick={() => AppClick('StyleID')}>
                    <img src={menu_styleid_icon} alt="" />
                    <div>
                        <div>{t('header.submenu.styleid.title')}</div>
                        <div>{t('header.submenu.styleid.appendices')}</div>
                    </div>
                </div>
                <div className="lyo" onClick={() => AppClick('FaceID')}>
                    <img src={menu_faceid_icon} alt="" />
                    <div>
                        <div>{t('header.submenu.faceid.title')}</div>
                        <div>{t('header.submenu.faceid.appendices')}</div>
                    </div>
                </div>
            </div>
            <div>
                {/* <div className="lyo" onClick={() => AppClick('Duo')}> */}
                <div className="lyo" onClick={() => {}}>
                    <img src={menu_two_faceid_icon} alt="" />
                    <div>
                        <div>{t('header.submenu.duo.title')}</div>
                        <div>{t('header.submenu.duo.appendices')}</div>
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    );
    const items = [
        // {
        //     label: t('header.home'),
        //     key: 'home',
        // },
        {
            label: <Popover
                content={content}
                arrow={false}
                placement={tasks[2].windowWidth > 768 ? "bottomLeft" : "bottom"}
                open={openPopover}
                onOpenChange={handleOpenChange}
                rootClassName='app-popover'
            >
                <div className="popover-but">
                    {t('header.app')}
                </div>
            </Popover>,
            key: 'app',
        },
        // {
        //     label: t('header.pricing'),
        //     key: 'pricing',
        // },
        {
            label:<div id="header-button">
                <Badge dot={tasks[3].isUserLoadingImage} offset={[10, 0]} size="small">
                    {t('header.myspace')}
                </Badge>
            </div>,
            key: 'myspace',
        },
        // {
        //     label: t('header.contactus'),
        //     key: 'contactus'
        // },
    ];
    const useItems = [
        // {
        //     key: '1',
        //     label: 'Profile',
        // },
        {
            key: '2',
            label: t('header.usersubmenu.transactions')
        },
        // {
        //     key: '3',
        //     label: 'My Gallery'
        // },
        {
            key: '4',
            label: t('header.usersubmenu.logout'),
        },
    ];
    const loginClick = () => {
        if (tasks[2].windowWidth <= 768) {
            onClose();
        }
        const currentUrl = `${location.pathname}${location.search}`;
        sessionStorage.setItem('redirectUrl', currentUrl);
        if (tasks[6].isEver) {
            auth.signinRedirect();
        } else {
            dispatch({
                type: 'set_login_intercept_modal',
                data: {
                    isModal: true,
                    isEver: tasks[6].isEver,
                    currentUrl: currentUrl
                }
            });
        }
    }
    // const signupClick = () => {
    //     // setIsLogin(true);
    //     dispatch({
    //         type: 'open_signup_modal',
    //     });
    // }
    const userOnChange = ({key}) => {
        switch (key) {
            case '1':
                
                break;
            case '2':
                navigate('/myspace', { state: { key: '2' } });
                if (tasks[2].windowWidth <= 768) {
                    onClose();
                }
                break;
            case '3':
                navigate('/myspace', { state: {key: '3'} });
                break;
            case '4':
                auth.signoutRedirect();
                break;
        
            default:
                break;
        }
    }

    const preventDefault = (e) => {
        e.preventDefault();
    };
    const onClose = () => {
        setOpen(false);
        document.body.style.overflow = '';
    };
    const showMenuClick = () => {
        setOpen(true);
        document.body.style.overflow = 'hidden';
    };
    const homeClick = () => {
        navigate('/');
    }

    return <div className="heart">
        <div className="menu">
            <div className="logo" onClick={homeClick}>
                <img className="img" src={logo} alt="" />
                <div>{LOGOTEXT}</div>
                <div>{LOGOBETA}</div>
            </div>
            {
                tasks[2].windowWidth > 768 ?
                <Menu
                    onClick={onClick}
                    selectedKeys={[current]}
                    mode="horizontal"
                    items={items}
                /> :
                    <div className="bill-fare">
                        {
                            auth.isAuthenticated ?
                            <div className="residual-integral">
                                <img src={integral_icon} alt="" />
                                <div>{ points }</div>
                            </div> : <></>
                        }
                        <Badge dot={tasks[3].isUserLoadingImage} offset={[-2, 4]} size="small">
                            <Button icon={<MenuOutlined />} onClick={showMenuClick} type="text"></Button>
                        </Badge>
                    </div>
            }
        </div>
        {
            tasks[2].windowWidth > 768 ?
                <div className="userAndMenu">
                    {/* <Radio.Group onChange={onChange} defaultValue={ userLanguage === 'zh-CN' ? 'zh' : 'en'} className="i18n-buts">
                        <Radio.Button value="en">English</Radio.Button>
                        <Radio.Button value="zh">中文</Radio.Button>
                    </Radio.Group> */}
                    {/* <Avatar className="hover" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} size={40} /> */}
                    {
                        auth.isAuthenticated ?
                            <div className="user-box">
                                <div className="residual-integral">
                                    {/* <div>{"Balance: "}</div> */}
                                    <img src={integral_icon} alt="" />
                                    <div>{ points }</div>
                                </div>
                                <Dropdown
                                    menu={{ items: useItems, onClick: userOnChange }}
                                    placement="bottomRight"
                                >
                                    <div className="user ant-dropdown-open">
                                        <Avatar className="img">
                                            {displayName?.split(' ')[1]?.charAt(0) || displayName?.split(' ')[0]?.charAt(0)}
                                        </Avatar>
                                        <div className="user-name">{displayName}</div>
                                    </div>
                                </Dropdown>
                            </div> :
                            <>
                                <Button
                                    type="link"
                                    className="login"
                                    onClick={loginClick}
                                >{t('header.login')}</Button>
                                {/* <Button
                                    type="primary"
                                    shape="round"
                                    className="signup"
                                    onClick={signupClick}
                                >{t('header.signup')}</Button> */}
                            </>
                    }
                </div> :
                <Drawer
                    title={<div className="header-menu">
                        <img className="img" src={logo} alt="" />
                        <Button icon={<CloseOutlined />} onClick={onClose} type="text"></Button>
                    </div>}
                    placement='top'
                    closable={false}
                    onClose={onClose}
                    open={open}
                    height={'100%'}
                    key='header_menu'
                    rootClassName="header-drawer"
                >
                    <Menu
                        onClick={onClick}
                        selectedKeys={[current]}
                        mode="vertical"
                        items={items}
                    />
                    <Divider />
                    <div className="userAndMenu">
                        {
                            auth.isAuthenticated ?
                                <Dropdown
                                    menu={{ items: useItems, onClick: userOnChange }}
                                    placement="bottom"
                                    overlayClassName='user-dropdown'
                                >
                                    <div className="user ant-dropdown-open">
                                        <Avatar className="img">
                                            {displayName?.split(' ')[1]?.charAt(0) || displayName?.split(' ')[0]?.charAt(0)}
                                        </Avatar>
                                        <div className="user-name">{displayName}</div>
                                    </div>
                                </Dropdown> :
                                <>
                                    <Button
                                        type="link"
                                        className="login"
                                        onClick={loginClick}
                                    >{t('header.login')}</Button>
                                    {/* <Button
                                        type="primary"
                                        shape="round"
                                        className="signup"
                                        onClick={signupClick}
                                    >{t('header.signup')}</Button> */}
                                </>
                        }
                    </div>
                </Drawer>
        }
        {/* <LoginModal />
        <SignupModal/> */}
        <LoginInterceptModal/>
    </div>
}

export default MyHeader;