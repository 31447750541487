import React, { useState, useEffect } from 'react';
import {
    Table,
    ConfigProvider,
    Button
} from 'antd';
import './index.css';
import none_data_two from '../../../images/none_data_two.png';
import { GetPointsHistory } from '../../../api/request.js';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useCatchAsyncError from '../../../hooks/useCatchAsyncError.jsx';
import PageLoading from '../../../components/pageLoading/index.jsx';
import { useTasks } from '../../../TasksContext';

const RechargeRecord = () => {
    const auth = useAuth();
    const tasks = useTasks();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [pointsListData, setPointsListData] = useState([]);
    const setError = useCatchAsyncError();
    const columns = [
        {
            title: t('myspace.table.data'),
            dataIndex: 'CreatedAt',
            key: 'date',
            render: (text) => {
                const date = new Date(text);
                return date.toUTCString();
            },
        },
        {
            title: t('myspace.table.operation'),
            dataIndex: 'EventType',
            key: 'operation',
        },
        {
            title: t('myspace.table.points'),
            dataIndex: 'Amount',
            key: 'points',
        },
    ];
    const [isLoadingList, setIsLoadingList] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                setIsLoadingList(true);
                if (auth && auth?.isAuthenticated) {
                    const data = { from: '2024-01-01T00:00:00Z', to: new Date().toISOString() }
                    const jwtPayload = auth?.user.access_token.split('.')[1]
                    const pointsHistory = await GetPointsHistory({ data: data, token: auth?.user?.access_token, jwtpayload: jwtPayload });
                    if (pointsHistory?.error?.code === "ERR_NETWORK") {
                        setError(pointsHistory?.error);
                    } else if (pointsHistory?.data) {
                        setPointsListData(pointsHistory?.data)
                    }
                }
                setIsLoadingList(false);
            } catch (error) {
                setIsLoadingList(false);
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth, auth?.user?.access_token, auth?.user?.profile?.sub]);

    const emptyContent = (
        <div className='none-data'>
            <img
                src={none_data_two}
                alt="No Data"
            />
            <div>{t('myspace.table.nodatatext')}</div>
            <Button
                type="primary"
                shape="round"
                className='but'
                onClick={() => navigate('/')}
            >{t('myspace.nodatabut')}</Button>
        </div>
    );

    if (isLoadingList) {
        return <PageLoading/>;
    }

    return <div className='recharge-record'>
        {
            pointsListData.length > 0 ?
            <div className='title-but'>
                <div></div>
                {/* <Button onClick={()=> {}} className='but'>{"Transaction"}</Button> */}
            </div> : <></>
        }
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        headerBg: '#F0F0F0',
                        headerColor: 'rgba(0, 0, 0, 1)',
                        colorText: 'rgba(113, 128, 150, 1)',
                    },
                },
            }}
        >
            <Table
                columns={columns}
                dataSource={pointsListData}
                locale={{ emptyText: emptyContent }}
                rowKey="ID"
                showHeader={false}
                pagination={{
                    position: [tasks[2].windowWidth > 768 ? 'bottomRight' : 'bottomCenter'],
                }}
            />
        </ConfigProvider>
    </div>
};

export default RechargeRecord;