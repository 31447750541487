const en = {
    header: {
        home: 'Home',
        app: 'App',
        pricing: 'Pricing',
        contactus: 'Contact Us',
        myspace: 'My Space',
        login: 'Log In',
        signup: 'Signup',
        submenu: {
            styleid: {
                title: 'StyleID',
                appendices: 'Artistic Avatar'
            },
            faceid: {
                title: 'FaceID',
                appendices: 'Realistic Avatar'
            },
            duo: {
                title: 'Duo',
                appendices: 'Duo Portrait'
            }
        },
        usersubmenu: {
            transactions: 'Transactions',
            logout: 'Log Out'
        },
        logininterceptmodal: {
            title: 'Register Award',
            footerbut: 'Log In Now',
            checktext: 'Not display in future',
            body: 'Get 5,000 Free Points for Registration!',
            appendices: 'Join us in November and take advantage of our exclusive offer for new users. Start your experience right now!'
        }
    },
    footer: {
        contactus: 'Contact Us',
        copyrightnotice: 'GoodAPP © 2024  All rights reserved.'
    },
    page404: {
        content: 'No Internet connection',
        refreshbut: 'Refresh'
    },
    home: {
        title: "Enhance Your Web Experience with AI",
        Introduction: 'GoodApp.ai Presents Best AI Tools for Your Daily Essentials!',
        activitycontent: 'Register in November and Receive 5,000 Free points!',
        gobut: 'Go',
        selectoptions: {
            styleid: 'StyleID',
            faceid: 'FaceID',
            duo: 'Duo'
        },
        bodyone: {
            title: 'StyleID',
            introduction: 'Transform Your Look with Artistic Flair.',
            body: "Unleash your creativity with our unique avatar generator! \
                Choose from a variety of vibrant artistic styles to create a stunning, \
                personalized avatar that truly represents you. Whether you prefer bold \
                brush strokes or delicate illustrations, our tool allows you to express \
                your individuality like never before. Stand out on social media and make \
                a statement with a custom avatar that's as unique as you are!",
            but: 'Generate'
        },
        bodytwo: {
            title: 'FaceID',
            introduction: 'Create Realistic Avatar with a Personal Touch.',
            body: "Capture your essence with our realistic portrait generator. \
                This tool delivers lifelike avatars that maintain a subtle artistic \
                flair while emphasizing authenticity. Perfect for those who appreciate \
                detail and realism, you can customize features to reflect your unique \
                personality. Ideal for professional profiles or personal branding, \
                help you leave a lasting impression.",
            but: 'Generate'
        },
        bodythree: {
            title: 'Duo',
            introduction: 'Capture Special Moments with Custom Duo Portraits.',
            body: "Celebrate life's milestones with our duo portrait generator! \
                Effortlessly create stunning photos featuring you and a partner \
                in any chosen setting—be it a joyful celebration, a cherished memory, \
                or a casual moment. Our tool allows you to personalize your scene, \
                ensuring each snapshot uniquely reflects your bond. \
                Perfect for sharing on social media or preserving memories, \
                transform your special moments into beautiful, lasting keepsakes today!",
            but: 'Coming soon'
        },
        bodyfour: {
            title: 'How it works ?',
            alter: 'Choose a template and upload your photo to generate exciting images',
            text1: 'Select Category',
            text2: 'Select themes',
            text3: 'Upload photo to generate'
        },
    },
    myspace: {
        myspace: 'My Space',
        transaction: 'Transaction',
        allbut: 'All',
        deletebut: 'Delete',
        cancelbut: 'Cancel',
        editbut: 'Edit',
        deletemodal: {
            cancelbut: 'Cancel',
            confirmbut: 'Confirm',
            bodytext1: 'Are you sure you want to delete this picture?',
            bodytext2: 'After being deleted, it cannot be restored',
        },
        errormessage: 'Image generation failure',
        generatebut: 'Generate',
        loadingmessage: 'Image generating',
        nodatatext: 'No picture available at the moment',
        nodatabut: 'Back',
        table: {
            nodatatext: 'No recharge records available at the moment',
            data: 'Date',
            operation: 'Operation',
            points: 'Points'
        }
    },
    pageloading: 'Loading...',
    app: {
        infoerrormessage: 'Please refresh and try again later!',
        generatebut: 'Generate',
        styleid: {
            title: 'StyleID',
            introduction: 'Generate Personalized Portraits with One Click, Choose from a Vast Array of Templates',
            body: 'Choose your preferred style from our meticulously designed templates—be it trendy fashion, romantic vintage, or cutting-edge technology. Then, upload a reference image you love along with a clear personal photo, and our powerful AI tools will instantly generate your unique portrait.',
            conclusion: 'Experience it now and embark on your personalized photography journey!'
        },
        faceid: {
            title: 'FaceID',
            introduction: 'Create Fantasy Portraits with Creative Prompts Using AI',
            body: 'Simply upload a clear portrait and add your creative prompts, and our AI engine will transform your ideas into unique portrait photos. From futuristic sci-fi and fantasy adventures to fairy-tale worlds, any scene you envision can be brought to life in your photos.',
            conclusion: 'Start now and create your own visual legend!'
        },
        duo: {
            title: 'Duo',
            bodytitle: 'Duo Portrait',
            introduction: 'Fun Duo Photos, Capture Beautiful Moments with Your Loved Ones',
            body: "Upload two clear portrait photos, and you can generate a variety of fun duo portraits with your family and friends. Whether it's romantic couple photos, joyful friend snapshots, or warm family portraits, we help you capture beautiful moments.",
            conclusion: 'Experience it now and create memories with your lover!'
        }
    },
    actionpage: {
        noticemodal: {
            title: 'Please upload a well-lit half-body photo with clear facial features',
            alter: 'Using the following image may not meet your expectations :',
            text1: 'Full-body photos',
            text2: 'Photos containing multiple subjects',
            text3: 'Black and white photos or low-quality images',
            text4: 'Photos where the face is covered by masks sunglasses, hands, shadows, etc.',
            text5: 'Photos with incomplete facial features, open mouth and show teeth',
            text6: 'Overdone the beauty and make-up',
            text7: 'Crooked headshot, incomplete head',
            text8: 'NSFW or illegal photos',
        },
        serviceerrormessage: 'Please refresh and try again later!',
        tomyspacemessage: 'Image generation failed. Please go to My Space to regenerate.',
        uploadbut: 'Upload',
        nopointserrormessage: 'Your points are running low, please contact us promptly for assistance.',
        networkerrormessage: 'Network Error!',
        uploadifimagemessage: 'You can only upload image files!',
        uploadifimagesizemessage: 'The image must be less than 6MB!',
        themestitle: 'Themes',
        generateimagenum: 'Generate {{num}} artworks a time.',
        uploadtitle: 'Upload your photo',
        formimageerror: 'Please select image',
        generate: 'Generate',
        loadingimage: 'Generating, expected to take 1 minute, please wait patiently',
        loadingimageerror: 'Image loading failure',
        styleid: {
            title: 'StyleID'
        },
        faceid: {
            title: 'FaceID'
        },
        duo: {
            title: 'Duo'
        }
    }
};
export default en;