// src/auth/authConfig.js
import { WebStorageStateStore } from 'oidc-client-ts';

export const oidcConfig = {
    authority: process.env.REACT_APP_GOODAPP_AUTHORITY_URL, // OIDC提供者的URL
    client_id: process.env.REACT_APP_GOODAPP_CLIENT_ID,
    redirect_uri: `${window.location.origin}/signinredir`,
    post_logout_redirect_uri: window.location.origin,
    response_type: 'code', // 使用授权码流程
    scope: 'openid profile email', // 请求的权限范围
    automaticSilentRenew: true, // 自动刷新令牌
    silent_redirect_uri: `${window.location.origin}/silent-renew`,
    usePkce: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
  };