import React, { useEffect, useState } from 'react';
import { useNavigate  } from "react-router-dom";
import {
    Select,
    Button
} from 'antd';
import './index.css';
import { useTranslation } from 'react-i18next';
import mobile_terminal_homebackground from '../../../images/mobile_terminal_homebackground.png';
import homebackground from '../../../images/homebackground.png';
import person1 from '../../../images/person1.png';
import person2 from '../../../images/person2.png';
import person3 from '../../../images/person3.png';
import person5 from '../../../images/person5.png';
import person6 from '../../../images/person6.png';
import person7 from '../../../images/person7.png';
import { useTasks, useTasksDispatch } from '../../../TasksContext';

const ContentOne = () => {
    const tasks = useTasks();
    const dispatch = useTasksDispatch();
    const { t } = useTranslation();
    const [selected, setSelected] = useState('StyleID');
    const navigate = useNavigate();

    useEffect(() => {
        if (tasks[3].selectApp) {
            setSelected(tasks[3].selectApp)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const handleChange = (value) => {
        setSelected(value);
        dispatch({
            type: 'set_select_app',
            selectApp: value,
        });
    };

    const onGenerateClick = () => {
        if (selected === "StyleID") {
            navigate('/app/StyleID')
        } else if (selected === "FaceID") {
            navigate('/app/FaceID')
        } else if (selected === "Duo") {
            navigate('/app/Duo')
        }
    }
    const selectOptions = [
        {
            value: 'StyleID',
            label: t('home.selectoptions.styleid'),
        },
        {
            value: 'FaceID',
            label: t('home.selectoptions.faceid'),
        },
        {
            value: 'Duo',
            label: t('home.selectoptions.duo'),
            disabled: true
        },
    ]

    return (
        <div
            className='content-one'
            style={{backgroundImage: `url(${tasks[2].windowWidth > 768 ? homebackground : mobile_terminal_homebackground})`}}
        >
            <img src={person1} alt="person1" className='person1' />
            <img src={person2} alt="person2" className='person2' />
            <img src={person3} alt="person3" className='person3' />
            {/* <img src={person4} alt="person4" className='person4' /> */}
            <img src={person5} alt="person5" className='person5' />
            <img src={person6} alt="person6" className='person6' />
            <img src={person7} alt="person7" className='person7' />
            {/* <img src={person8} alt="person8" className='person8' /> */}
            {/* <img src={mouse} alt="mouse" className='mouse' /> */}
            <div className='content-title'>
                {t('home.title')}
            </div>
            <div className='content-select'>
                <div className='select'>
                    <Select
                        defaultValue="StyleID"
                        value={selected}
                        onChange={handleChange}
                        options={selectOptions}
                    />
                </div>
                <Button
                    type="primary"
                    shape="round"
                    className='but'
                    onClick={ onGenerateClick }
                >
                    {t('home.gobut')}
                </Button>
            </div>
            <div className='content-text'>
                {t('home.Introduction')}
            </div>
            <div className='content-activity'>
                {t('home.activitycontent')}
            </div>
        </div>
    );
};

export default ContentOne;