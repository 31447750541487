import React from 'react';
import ContentOne from './contentOne';
// import ContentTwo from './contentTwo';
// import ContentThree from './contentThree';
import ContentFour from './contentFour';
import './index.css';

const Home = () => {
    return (
        <div className='outlayer-home'>
            <ContentOne/>
            {/* <ContentThree/> */}
            {/* <ContentTwo/> */}
            <ContentFour />
        </div>
    );
};

export default Home;