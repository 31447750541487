import React, { useEffect, useState } from 'react';
import './App.css';
import './fonts.css';
import { Layout, Flex } from 'antd';
import MyHeader from './components/header';
import MyFooter from './components/footer';
import RouterView from './routes';
import { AuthProvider } from "react-oidc-context";
import { oidcConfig } from './auth/authConfig';
import { TasksProvider } from './TasksContext';
import { useLocation } from 'react-router-dom';

const { Header, Footer, Content } = Layout;

const App = () => {
    const location = useLocation();
    const [isScrolled, setIsScrolled] = useState(false);

    const handleScroll = () => {
        if (window.innerWidth <= 768) {
            if (window.scrollY > 1) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <AuthProvider {...oidcConfig}>
            <TasksProvider>
                <Flex gap="middle" wrap="wrap" className='app'>
                    <Layout className='layout-style'>
                        <Header className='header-style' style={{ background: isScrolled ? 'rgba(250, 250, 250, 1)' : 'transparent' }}>
                            <MyHeader />
                        </Header>
                        <Content className='content-style' style={{ backgroundColor: location.pathname === '/contactus' ? 'rgba(250, 250, 250, 1)' : 'rgba(255, 255, 255, 1)' }}>
                            <RouterView></RouterView>
                        </Content>
                        <Footer className='footer-style'>
                            <MyFooter />
                        </Footer>
                    </Layout>
                </Flex>
            </TasksProvider>
        </AuthProvider>
    )
};
export default App;
