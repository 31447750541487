import React from "react";
import './index.css';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import notice_modal_image from '../../images/notice_modal_image.webp';
import { useTasks, useTasksDispatch } from '../../TasksContext';

const NoticeModal = () => {
    const tasks = useTasks();
    const dispatch = useTasksDispatch();
    const { t } = useTranslation();
    const onCancel = () => {
        dispatch({
            type: 'set_notice_modal_show',
            data: false
        });
    }

    return (
        <Modal
            title={<div style={{ height: "clamp(6px, 4rem, 40px)" }}></div>}
            centered
            width={tasks[2].windowWidth < 768 ? '80%' : 800 }
            open={tasks[7].noticeModalShow}
            onCancel={onCancel}
            footer={null}
            wrapClassName="notice-modal"
        >
            <div className="notice-modal-content">
                <img src={notice_modal_image} alt="" />
                <div>
                    <div className="title">{t('actionpage.noticemodal.title')}</div>
                    <div className="alter">{t('actionpage.noticemodal.alter')}</div>
                    <ul className="text">
                        <li>{t('actionpage.noticemodal.text1')}</li>
                        <li>{t('actionpage.noticemodal.text2')}</li>
                        <li>{t('actionpage.noticemodal.text3')}</li>
                        <li>{t('actionpage.noticemodal.text4')}</li>
                        <li>{t('actionpage.noticemodal.text5')}</li>
                        <li>{t('actionpage.noticemodal.text6')}</li>
                        <li>{t('actionpage.noticemodal.text7')}</li>
                        <li>{t('actionpage.noticemodal.text8')}</li>
                    </ul>
                </div>
            </div>
        </Modal>
    )
}

export default NoticeModal;