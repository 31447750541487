import React, { useEffect, useState } from 'react';
import './index.css';
import StyleIDList from './styleIDList';
import FaceIDList from './faceIDList';
import DuoList from './duoList/index.jsx';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation  } from "react-router-dom";
import { GetScenesInfo } from '../../api/request.js';
import useCatchAsyncError from '../../hooks/useCatchAsyncError.jsx';
import PageLoading from '../../components/pageLoading/index.jsx';

const AppList = () => {
    const { t } = useTranslation();
    const [type, setType] = useState(1);
    const [allSceneList, setAllSceneList] = useState([]);
    const location = useLocation();
    const setError = useCatchAsyncError();
    const [isLoadingList, setIsLoadingList] = useState(true);
    // const [textSceneList, setTextSceneList] = useState([]);
    // const [imageSceneList, setImageSceneList] = useState([]);
    // const onChange = (key) => {
    //     // console.log(key);
    // };
    // const items = [
    //     {
    //         key: '1',
    //         label: 'All Models',
    //         children:
    //             <Flexbox
    //                 key="all-models"
    //                 sceneList={allSceneList}
    //                 setSceneList={setAllSceneList}
    //                 module="all-models"
    //             />
    //     },
    //     {
    //         key: '2',
    //         label: 'Text To Image',
    //         children:
    //             <Flexbox
    //                 key="text-to-image"
    //                 sceneList={textSceneList}
    //                 setSceneList={setTextSceneList}
    //                 module="text-to-image"
    //             />
    //     },
    //     {
    //         key: '3',
    //         label: 'Image To Image',
    //         children:
    //             <Flexbox
    //                 key="image-to-image"
    //                 sceneList={imageSceneList}
    //                 setSceneList={setImageSceneList}
    //                 module="image-to-image"
    //             />
    //     }
    // ];

    useEffect(() => {
        try {
            if (location.pathname) {
                const parts = location.pathname.split("/").slice(-1)[0];
                let type = 1;
                if (parts === 'StyleID') {
                    type = 1;
                } else if (parts === 'FaceID') {
                    type = 2;
                } else if (parts === 'Duo') {
                    type = 3;
                }

                setType(type);
                (async () => {
                    setIsLoadingList(true);
                    const scenesInfo = await GetScenesInfo({ type });
                    if (scenesInfo.data?.code === 1000) {
                        const getScenesList = scenesInfo.data.data
                        if (getScenesList.length > 0) {
                            const newList = JSON.parse(JSON.stringify(getScenesList));
                            for (let i = 0; i < newList.length; i++) {
                                newList[i].scenes = newList[i].scenes.sort((a, b) => a.type - b.type)
                            }
                            newList.sort((a, b) => a.order - b.order);
                            setAllSceneList(newList);
                            // setTextSceneList(newList);
                            // setImageSceneList(newList);
                        }
                    } else if (scenesInfo?.error?.code === 'ERR_NETWORK') {
                        setError(scenesInfo?.error);
                    } else {
                        message.error(t('app.infoerrormessage'));
                        setAllSceneList([]);
                    }
                    setIsLoadingList(false);
                })()
            }
        } catch (error) {
            setIsLoadingList(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    if (isLoadingList) {
        return <PageLoading/>;
    }

    return (
        <div className='applist'>
            {/* <Tabs
                defaultActiveKey="1"
                items={items}
                onChange={onChange}
                tabBarGutter={36}
            /> */}
            {
                type === 1 ?
                    <StyleIDList
                        key="StyleID-models"
                        sceneList={allSceneList}
                        type={type}
                        module="StyleID-models"
                    /> :
                type === 2 ?
                    <FaceIDList
                        key="FaceID-models"
                        sceneList={allSceneList}
                        type={type}
                        module="FaceID-models"
                    /> :
                type === 3 ?
                    <DuoList
                        key="Duo-models"
                        sceneList={allSceneList}
                        type={type}
                        module="Duo-models"
                    /> : <></>
            }
        </div>
    );
};

export default AppList;