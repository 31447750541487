import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import './index.css';
import img_404 from '../../images/img_404.png';

const ErrorFallback = ({ resetErrorBoundary }) => {
    const { t } = useTranslation();

    return (
        <div role="alert" className='error'>
            <div className='error-box'>
                <img src={img_404} alt="" />
                <div>{t('page404.content')}</div>
                {/* <p>{error.message}</p> */}
                <button onClick={resetErrorBoundary}>{t('page404.refreshbut')}</button>
            </div>
        </div>
    )
};

const ErrorBoundaryComponent = ({ children }) => (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
        {children}
    </ErrorBoundary>
);

export default ErrorBoundaryComponent;