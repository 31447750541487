// src/auth/SilentRenew.jsx
import React from 'react';

const SilentRenew = () => {
  // const { signinSilentCallback } = useOidc();

  // useEffect(() => {
  //   const handleSilentRenew = async () => {
  //     try {
  //       await signinSilentCallback();
  //     } catch (error) {
  //       console.error('Silent renew error:', error);
  //     }
  //   };
  //   handleSilentRenew();
  // }, [signinSilentCallback]);

  return <div></div>;
};

export default SilentRenew;