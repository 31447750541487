import { createContext, useContext, useReducer, useEffect } from 'react';
// import { message } from 'antd';

const TasksContext = createContext(null);

const TasksDispatchContext = createContext(null);

export function TasksProvider({ children }) {
    const [tasks, dispatch] = useReducer(
        tasksReducer,
        initialTasks
    );
    useEffect(() => {
        const handleResize = () => {
            dispatch({
                type: 'set_window_width',
                width: window.innerWidth
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <TasksContext.Provider value={tasks}>
            <TasksDispatchContext.Provider value={dispatch}>
                {children}
            </TasksDispatchContext.Provider>
        </TasksContext.Provider>
    );
}

export function useTasks() {
    return useContext(TasksContext);
}

export function useTasksDispatch() {
    return useContext(TasksDispatchContext);
}

function tasksReducer(tasks, action) {
    // if (action.type !== 'set_window_width') {
    //     console.log('action', action)
    // }
    switch (action.type) {
        case 'open_login_modal': {
            const newTask = [...tasks];
            newTask[0].isShow = true;
            return newTask;
        }
        case 'cancel_login_modal': {
            const newTask = [...tasks];
            newTask[0].isShow = false;
            return newTask;
        }
        case 'open_signup_modal': {
            const newTask = [...tasks];
            newTask[0].isShow = false;
            newTask[1].isShow = true;
            return newTask;
        }
        case 'cancel_signup_modal': {
            const newTask = [...tasks];
            newTask[1].isShow = false;
            return newTask;
        }
        case 'set_window_width': {
            const newTask = [...tasks];
            newTask[2].windowWidth = action.width;
            return newTask;
        }
        case 'set_user_loading_num': {
            const newTask = [...tasks];
            newTask[3].userLoadingNum = action.data;
            return newTask;
        }
        case 'set_is_user_loading_image': {
            const newTask = [...tasks];
            newTask[3].isUserLoadingImage = action.data;
            return newTask;
        }
        case 'set_select_app': {
            const newTask = [...tasks];
            newTask[4].selectApp = action.selectApp;
            return newTask;
        }
        case 'deleted': {
            return tasks.filter(t => t.id !== action.id);
        }
        case 'set_scene_gen_status': {
            const newTask = [...tasks];
            newTask[5].sceneGenStatus = action.value;
            return newTask;
        }
        case 'set_login_intercept_modal': {
            const newTask = [...tasks];
            newTask[6] = action.data;
            localStorage.setItem('InterceptModal', JSON.stringify(action.data))
            return newTask;
        }
        case 'set_notice_modal_show': {
            const newTask = [...tasks];
            newTask[7].noticeModalShow = action.data;
            return newTask;
        }
        // case 'login': {
        //     const {
        //         userid,
        //         name,
        //         token,
        //         expires_in,
        //         email,
        //         islogin,
        //     } = action.data
           
        //     const newTask = [...tasks];
        //     localStorage.setItem('login', JSON.stringify({ userid, name, islogin, expires_in, email, token }));
        //     newTask[3].userid = userid;
        //     newTask[3].name = name;
        //     newTask[3].token = token;
        //     newTask[3].expires_in = expires_in;
        //     newTask[3].email = email;
        //     newTask[3].islogin = islogin;
        //     return newTask;
        // }
        // case 'refresh_user_info': {
        //     const { userid, name, islogin, token, expires_in, email } = action.data;
        //     localStorage.setItem('login', JSON.stringify({ userid, name, islogin, expires_in, email, token }));
        //     const newTask = [...tasks];
        //     newTask[3].userid = userid;
        //     newTask[3].name = name;
        //     newTask[3].token = token;
        //     newTask[3].expires_in = expires_in;
        //     newTask[3].email = email;
        //     newTask[3].islogin = islogin;
        //     return newTask;
        // }
        // case 'log_out': {
        //     const newTask = [...tasks];
        //     localStorage.setItem('login', JSON.stringify({}));
        //     newTask[3].islogin = false;
        //     newTask[3].userid = '';
        //     newTask[3].name = '';
        //     newTask[3].token = '';
        //     newTask[3].email = '';
        //     newTask[3].expires_in = 0;
        //     return newTask;
        // }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}

const initialTasks = [
    {
        modalName: 'login',
        isShow: false
    },
    {
        modalName: 'signup',
        isShow: false
    },
    {
        windowWidth: window.innerWidth
    },
    {
        userLoadingNum: 0,
        isUserLoadingImage: false
    },
    {
        selectApp: "Portrait"
    },
    {
        sceneGenStatus: 0
    },
    {
        isModal: localStorage.getItem('InterceptModal') ? JSON.parse(localStorage.getItem('InterceptModal')).isModal : false,
        isEver: localStorage.getItem('InterceptModal') ? JSON.parse(localStorage.getItem('InterceptModal')).isEver : false,
        currentUrl: localStorage.getItem('InterceptModal') ? JSON.parse(localStorage.getItem('InterceptModal')).currentUrl : '/'
    },
    {
        noticeModalShow: false
    }
];