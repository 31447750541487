import React from "react";
import './index.css';
import { useTranslation } from 'react-i18next';
import loading_img2 from '../../images/loading_img2.png';

const PageLoading = () => {
    const { t } = useTranslation();

    return <div className="page-loading">
        <div className='loading-box'>
            <img src={loading_img2} alt="" />
            <div>{t('pageloading')}</div>
        </div>
    </div>
}
export default PageLoading;