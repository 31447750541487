import axios from 'axios';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const userUrl = process.env.REACT_APP_GOODAPP_USER_URL;
const imageUrl = process.env.REACT_APP_GOODAPP_IMAGE_URL;

export const GetPointsHistory = ({data, token, jwtpayload}) => {
    return new Promise((resolve) => {
        axios.get(`${userUrl}/user/public/point/history`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'X-JWT-Payload': jwtpayload
            },
            params: data,
        }).then(({ data }) => {
            resolve({ data: data?.data?.history ? JSON.parse(data?.data?.history) : null, error: null });
        }).catch((error) => {
            resolve({ data: null, error });
        })
    })
};

export const GetPoints = ({token, jwtpayload}) => {
    return new Promise((resolve) => {
        axios.get(`${userUrl}/user/public/point/get`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'X-JWT-Payload': jwtpayload
            },
        }).then(({data}) => {
            resolve({ data, error: null });
        }).catch((error) => {
            resolve({ data: null, error });
        })
    })
};

export const DeleteSelectedTask = ({data, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`${imageUrl}/v1/images/deleteimagestask`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                resolve(data.data)
            } else {
                reject(new Error());
            }
        }).catch((error) => {
            reject(error);
        })
    })
};

export const TaskRegenAPI = ({data, token}) => {
    return new Promise((resolve) => {
        axios.post(`${imageUrl}/v1/images/taskregen`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            resolve( { data, error: null });
        }).catch((error) => {
            resolve( { data: null, error });
        })
    })
};

export const GetImagesHistory = ({data, token, timeout}) => {
    return new Promise((resolve) => {
        axios.post(`${imageUrl}/v1/images/getimageshistory`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            timeout: timeout
        }).then(({ data }) => {
            resolve({data, error: null});
        }).catch((error) => {
            resolve({data:null, error});
        })
    })
};

export const GetScenesInfo = (params) => {
    return new Promise((resolve) => {
        axios.get(`${imageUrl}/v1/images/getscenesinfo`, {
            headers: {
                'Content-Type': 'application/json',
            },
            params: params
        }).then(({ data }) => {
            resolve({ data, error: null });
        }).catch((error) => {
            resolve({ data: null, error });
        })
    })
};

export const InfoByTaskID = ({taskid, uid, token}) => {
    return new Promise((resolve) => {
        axios.post(`${imageUrl}/v1/images/infobytaskid`, {taskid, uid},{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            timeout: 3000
        }).then(({data}) => {
            resolve({ data:data, error: null });
        }).catch(error => {
            resolve({ data: null, error });
        });
    })
}
export const StyleIdAPI = ({formData, token}) => {
    return new Promise((resolve) => {
        axios.post(`${imageUrl}/v1/images/stylersync`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            resolve({ data:data, error: null });
        }).catch((error) => {
            resolve({ data: null, error });
        })
    })
};
export const DuoIdAPI = ({formData, token}) => {
    return new Promise((resolve) => {
        axios.post(`${imageUrl}/v1/images/imagegenbysceneinfo`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            resolve({ data:data, error: null });
        }).catch((error) => {
            resolve({ data: null, error });
        })
    })
};
export const FaceIdAPI = ({formData, token}) => {
    return new Promise((resolve) => {
        axios.post(`${imageUrl}/v1/images/photosync`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            resolve({ data:data, error: null });
        }).catch((error) => {
            resolve({ data: null, error });
        })
    })
};
export const StyleIdGetStyleNode = (config) => {
    return new Promise((resolve, reject) => {
        axios.get(`http://${config.ip}/v1/stylerid/getimagestyle?type=9`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + config.token
            },
        }).then((res) => {
            resolve(res.data);
        }).catch((error) => {
            reject(error);
        })
    })
};