import React from "react";
import {
    Button,
    Skeleton,
    Image,
} from 'antd';
import {
    RightOutlined
} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import './index.css';
import hot from '../../../images/hot.png';
import integral_icon from '../../../images/integral_icon.png';
import homeicon from '../../../images/homeicon.png';
import home_faceid_bg from '../../../images/home_faceid_bg.png';

const FaceIDList = (props) => {
    const { sceneList, module, type } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();

    const generateClick = (item) => {
        window.gtag('event', 'button_click', {
            'event_category': item.category,
            'Click_scene': 'event_category'
        });
        navigate('/faceid', {
            state: {
                type: type,
                name: item.category
            }
        });
    }

    return <div className="app-faceid-box" key={module}>
        <div className="app-faceid-header">
            <img src={homeicon} alt="" onClick={() => navigate(`/`)} />
            <div><RightOutlined /></div>
            <div>{t('app.faceid.title')}</div>
        </div>
        <div className='content'>
            <div className='content-row'>
                <div className={`content-row-img`}>
                    <img src={home_faceid_bg} alt="" />
                </div>
                <div className={`content-row-text`}>
                    <h2>{t('app.faceid.title')}</h2>
                    <div>
                        <p><strong>{t('app.faceid.introduction')}</strong></p>
                        <p>{t('app.faceid.body')}</p>
                        <p>{t('app.faceid.conclusion')}</p>
                    </div>
                </div>
            </div>
        </div>
        {
            sceneList.length > 0 ? sceneList.map((item) => <div className="app-faceid-box-item" key={item.category}>
                <div className="app-item" onClick={() => generateClick(item)}>
                    <Image
                        alt={item.category}
                        src={item.scenes[0].imageurl}
                        className="app-item-img"
                        preview={false}
                        placeholder={<div
                            style={{
                                backgroundColor: 'white',
                                borderRadius: '16px',
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            <Skeleton.Image active={true} className="app-item-img" />
                        </div>}
                    />
                    <Image
                        alt={item.category}
                        src={item.scenes[1] ? item.scenes[1].imageurl : item.scenes[0].imageurl }
                        className="app-item-img-two"
                        preview={false}
                        placeholder={<div
                            style={{
                                backgroundColor: 'white',
                                borderRadius: '16px',
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            <Skeleton.Image active={true} className="app-item-img" />
                        </div>}
                    />
                </div>
                <div className="app-item-box">
                    <div className="app-item-styler">
                        <div>{item.category}</div>
                        {
                            item.hot ? <img src={hot} alt="" /> : <></>
                        }
                    </div>
                    <div className="app-item-action">
                        <div className="app-item-integral">
                            <img src={integral_icon} alt="" />
                            <div>{item.point}</div>
                        </div>
                        <Button type="primary" shape="round" className='but' onClick={() => generateClick(item)}>
                            {t('app.generatebut')}
                        </Button>
                    </div>
                </div>
            </div>) : <></>
        }
    </div>
}

export default FaceIDList;