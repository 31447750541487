import { openDB } from 'idb';

export async function openDatabase() {
    return await openDB('MyDatabase', 1, {
        upgrade(db) {
            db.createObjectStore('myStore', { keyPath: 'id' });
        },
    });
}

// async function addData(db, data) {
//     try {
//         await db.add('myStore', data);
//     } catch (error) {
//         // eslint-disable-next-line no-console
//         console.log('addData', error);
//     }
// }

async function getData(db, id) {
    try {
        return await db.get('myStore', id);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log('getData', error);
    }
}

async function updateData(db, data) {
    try {
        await db.put('myStore', data);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log('updateData', error);
    }
}

async function deleteData(db, id) {
    try {
        await db.delete('myStore', id);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log('deleteData', error);
    }
}

export async function updataIDB(params) {
    const db = await openDatabase();
    await updateData(db, params);
    db.close();
}

export async function getdataIDB(params) {
    const db = await openDatabase();
    return await getData(db, params);
}
export async function deletedataIDB(params) {
    const db = await openDatabase();
    await deleteData(db, params);
    db.close();
}

// async function main() {
//     const db = await openDatabase();

//     await addData(db, { id: 1, name: 'Item 1' });
//     await addData(db, { id: 2, name: 'Item 2' });

//     const item = await getData(db, 1);
//     console.log('Retrieved:', item);

//     await updateData(db, { id: 1, name: 'Updated Item 1' });

//     await deleteData(db, 2);

//     db.close();
// }

// main().catch(console.error);